*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  --caribbean_green: #029d70;
  --caribbean_green_hover: #13d9a0;
  --prussian_blue: #013a51;
  --blue_saphire: #00596c;
  --police_blue: #275668;
  --milk: #fbfcf6;
  --white: #fff;
  --black: #010101;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb:vertical {
  margin: 5px;
  background-color: #999;
  -webkit-border-radius: 5px;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  height: 5px;
  display: block;
}

nav,
nav a {
  white-space: nowrap;
}

body,
nav.header-desktop a,
nav.header-desktop a.logo:hover,
nav.scroll_down a {
  color: var(--prussian_blue);
}

.cs-down,
.cs-down:before,
.image-header {
  left: 50%;
  position: absolute;
}

.tab-body,
.tab-header,
nav {
  display: flex;
}

#scrollToTop,
.breadcrumb-arrows li.active,
nav a {
  font-weight: 700;
}

#scrollToTop,
.breadcrumb-arrows li a {
  text-decoration: none;
}

.btn,
.tab-header .tab-btn:focus {
  outline: 0;
}

#scrollToTop,
.btn {
  text-align: center;
}

.btn--white,
.cs-down:before,
body,
nav.scroll_down {
  background-color: var(--white);
  box-shadow: 0 6px 10px rgb(19 40 29 / 10%);
}

nav.scroll_down a,
nav.scroll_down span {
  color: var(--prussian_blue);
}

nav.scroll_down .active,
nav.scroll_down a.menu:hover {
  border-bottom: 2px solid var(--prussian_blue);
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  overflow-x: hidden !important;
  font-family: Nunito, sans-serif;
  font-weight: 400;
}

nav {
  position: fixed;
  top: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 1rem 3rem;
  z-index: 9;
  transition: 0.5s;
}

nav a {
  padding: 0.8rem 0;
  color: var(--white);
  margin: 0 1.5rem;
  text-transform: uppercase;
  position: relative;
}

nav .active {
  border-bottom: 2px solid var(--white);
}

nav .flex_content:nth-child(2) a:after {
  transition: 0.5s;
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  top: 100%;
  height: 2px;
  background: var(--caribbean-green);
}

nav .flex_content:nth-child(2) a:hover:after {
  width: 100%;
}

nav .flex_content:last-child a {
  float: right;
}

nav .ham {
  display: none;
}

nav .logo {
  font-size: 1.6em;
  font-weight: 900;
}

nav.scroll_down {
  box-shadow: 0 6px 10px rgb(19 40 29 / 10%);
}

nav.scroll_down .active,
nav.scroll_down a.menu:hover {
  border-bottom: 2px solid var(--caribbean_green);
}

nav .menu .active {
  border-bottom: 2px solid var(--prussian_blue);
}

.breadcrumb-arrows {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.breadcrumb-arrows li:not(:last-child)::after {
  content: ">";
  margin: 0 10px;
}

.breadcrumb-arrows li:last-child::after {
  content: "";
  margin: 0;
}

@media (max-width: 1023px) {
  nav {
    padding: 1rem;
  }

  .mobile-menu {
    z-index: 60;
  }

  .mobile-menu__content {
    -webkit-overflow-scrolling: auto;
    top: 0;
    left: 0px;
    bottom: 0px;
    right: 0px;
    position: fixed;
    height: 100vh;
    overflow-y: auto;
    pointer-events: auto;
    z-index: 40;
  }
}

.fadeInDown {
  animation: fadeInDown .12s cubic-bezier(.25, .46, .45, .94) both;
}

.stroke {
  color: transparent;
  -webkit-text-stroke: 1.5px var(--prussian_blue);
}

.cs-down {
  height: 35px;
  width: 20px;
  border-radius: 10px;
  border: 2px solid var(--white);
  bottom: 5%;
  margin-left: -10px;
}

.cs-down:before {
  content: "";
  height: 5px;
  width: 5px;
  border-radius: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation: 3s infinite UpAndDown;
  animation: 3s infinite UpAndDown;
}

@-webkit-keyframes UpAndDown {

  0%,
  100% {
    top: 30%;
  }

  50% {
    top: 70%;
    opacity: 0.5;
    -webkit-transform: translate(-50%, -50%) scale(0.8);
    transform: translate(-50%, -50%) scale(0.8);
  }
}

@keyframes UpAndDown {

  0%,
  100% {
    top: 30%;
  }

  50% {
    top: 70%;
    opacity: 0.5;
    -webkit-transform: translate(-50%, -50%) scale(0.8);
    transform: translate(-50%, -50%) scale(0.8);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.guide-icon {
  z-index: 1;
}

.guide-list-item:not(:last-child):after {
  content: "";
  position: absolute;
  height: 100%;
  left: 15px;
  z-index: 0;
  width: 0;
  top: 0;
  border-left: 1px dashed #bbb;
}

.guide-list-item:not(:last-child) {
  padding-bottom: 30px;
}

.guide-list-item {
  min-height: 80px;
}

.guide-list-item:last-child {
  min-height: initial;
}

.cursor {
  width: 40px;
  height: 40px;
  border: 1px solid var(--caribbean-green);
  -webkit-transition: 0.15s;
  transition: 0.2s ease-out;
  border-radius: 50%;
}

.cursor::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: var(--caribbean-green);
  -webkit-transition: 0.2s;
  transition: 0.2s ease-out;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#scrollToTop {
  top: auto !important;
  padding: 11px;
  line-height: 25px;
  color: #fff;
  border: 2px solid transparent;
  border-radius: 100%;
  bottom: -38px;
  cursor: pointer;
  height: 50px;
  opacity: 0;
  position: fixed;
  right: 12px !important;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  visibility: hidden;
  width: 50px;
  z-index: 99;
}

#scrollToTop.show {
  bottom: 50px;
  opacity: 1;
  visibility: visible;
  background: var(--prussian_blue);
}

#scrollToTop:hover {
  border-color: var(--prussian_blue);
  color: #fff;
}

#mobile-nav-menu {
  margin-top: 60px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 0;
  position: fixed;
  overflow-y: auto;
  pointer-events: auto;
  transition: height 0.3s ease-in-out;
}

#mobile-nav-menu.active {
  height: 100vh;
}

button span {
  pointer-events: none;
}

.is-truncated {
  height: 90px;
  max-height: 90px;
  overflow: hidden;
}

.is-truncated:after {
  background: -webkit-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)100%);
  background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)100%);
  background: -o-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)100%);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)100%);
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60px;
  width: 100%;
}

.font-light {
  font-size: 0.875rem;
}

.btn {
  border-radius: 50px;
  color: var(--prussian_blue);
  display: inline-block;
  font-size: 15px;
  line-height: 16px;
  padding: 10px 35px;
  transition: 0.4s;
}

.btn--green {
  color: var(--milk);
  background-color: var(--caribbean_green);
}

.btn--blue {
  color: var(--milk);
  background-color: #192f44;
  box-sizing: border-box;
}

.btn--green-outline {
  border: 2px solid var(--caribbean_green);
}

.btn--green:hover {
  background-color: var(--caribbean_green_hover);
}

.btn--green-outline:hover {
  color: var(--caribbean_green);
}

.col-md-6 {
  flex: 0 0 50%;
}

.col-md-5 {
  flex: 0 0 41.66667%;
}

.poster-1 {
  left: 0%;
  z-index: 5;
}

.poster-2 {
  left: 12.5%;
  z-index: 4;
}

.poster-3 {
  left: 25%;
  z-index: 3;
}

.poster-4 {
  left: 37.5%;
  z-index: 2;
}

.poster-5 {
  left: 50%;
  z-index: 1;
}

.drop-shadow {
  -webkit-filter: drop-shadow(2px 0px 2px#000) !important;
  filter: drop-shadow(2px 0px 2px#000) !important;
}
.page_404 {
  padding: 40px 0;
  background: #fff;
}
.page_404 img {
  width: 100%;
}
.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/722246/screenshots/3066818/404-page.gif);
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
}
.four_zero_four_bg h1 {
  font-size: 80px;
}
.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #325c83;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}
#article_menu .nav-item.is-active .img-black,#article_menu .nav-item:hover .img-black{
  filter:none
}
#article_menu .nav-item{
  position:relative
}
#article_menu .nav-item.is-active a,#article_menu .nav-item:hover a{
  color:#325c83
}
#article_menu .nav-item+.nav-item:before{
  content:"";
  width:10px;
  height:10px;
  background-image:url(https://homepage.momocdn.net/next-js/_next/static/public/article/arrow-right.svg);
  background-repeat:no-repeat;
  background-size:auto 100%;
  left:-6px;
  top:50%;
  transform:translateY(-50%);
  position:absolute;
  opacity:.6
}
#article_menu .cat-item+.cat-item:before{
  left:0;
  width:2px;
  height:21px;
  background-image:url(https://homepage.momocdn.net/next-js/_next/static/public/article/Rectangle.svg)
}